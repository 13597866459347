.App {
  text-align: left;
}

.creme {
  background-color: #f5f5f5;
}

.black {
  background-color: #111111;
}

.tatami {
  background-color: #8aa87d;
}

.marginContainer {
  padding: 32px;
}

.marginContainerHorizontal {
  padding: 0 32px 32px;
  transform: scale(1); /* you need a scale here to allow it to transition in both directions */
  transition: 1s all ease;
}

.marginContainerTall {
  padding: 64px 32px;
}

.marginContainerHorizontal {
  padding-left: 32px;
  padding-right: 32px;
}

.marginVerticalContainer {
  padding-top: 32px;
  padding-bottom: 32px;
}

.dimmedImage {
  position: relative;
  :first-child {
    z-index: 2;
  }
}

.dimmedImage:after {
  content: " ";
  z-index: 1;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.dimmedImageDark {
  position: relative;
  :first-child {
    z-index: 2;
  }
}

.dimmedImageDark:after {
  content: " ";
  z-index: 1;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
}

.dimmedImageLight {
  position: relative;
  :first-child {
    z-index: 2;
  }
}

.dimmedImageLight:after {
  content: " ";
  z-index: 1;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.0);
}

.comingSoon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.comingSoon:after {
  content: " ";
  z-index: 4;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

img#kaiju {
  max-height: 100%;
}
